import React from 'react';
import './NumberInputSpinner.css';

const NumberInputSpinner = ({ name, value, onChange, min = 1 }) => {
    const handleIncrement = () => {
        if (value < min) return;
        onChange({ target: { name, value: String(Number(value) + 1) } });
    };

    const handleDecrement = () => {
        if (value <= min) return;
        onChange({ target: { name, value: String(Number(value) - 1) } });
    };

    return (
        <div className="number-input-spinner">
            <button type="button" onClick={handleDecrement} className="spinner-button">-</button>
            <input type="text" name={name} value={value} onChange={onChange} className="spinner-input" />
            <button type="button" onClick={handleIncrement} className="spinner-button">+</button>
        </div>
    );
};

export default NumberInputSpinner;
