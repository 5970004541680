import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import PrintStore from './Pages/PrintStore/PrintStore';
import ShopSelection from './Pages/ShopSelection/ShopSelection';
import Home from './Pages/HomePage/Home';
import './App.css';
import Navbar from './Pages/HomePage/Navbar/Navbar';
import OrderSummary from './Pages/OrderSummary/OrderSummary';
import ProductPage from './Pages/PrintStore/ProductPage';
import Footer from './Pages/HomePage/Footer/Footer';
import { AuthProvider } from './AuthContext';

function App() {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <AuthProvider>
        <Router>
          <Navbar />
          <div className="App">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/print-store" element={<PrintStore />} />
              <Route path="/product/:id" element={<ProductPage />} />
              <Route path="/shop-selection" element={<ShopSelection />} />
              <Route path="/order_confirmation" element={<OrderSummary />} />
            </Routes>
          </div>
          <Footer />
        </Router>
      </AuthProvider>
    </GoogleOAuthProvider >
  );
}

export default App;
