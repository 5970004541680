import React, { useState, useEffect } from 'react';
import ProgressBar from './ProgressBar';
import './PopupProgressLoader.css';
import gifSrc from '../../assets/printAnimation.gif'

const PopupProgressLoader = ({ isComplete, onComplete }) => {
    const [progress, setProgress] = useState(0);
    const [loadingText, setLoadingText] = useState('Verifying payment...');

    const updateLoadingText = (progress) => {
        if (progress < 25) {
            setLoadingText('Verifying payment...');
        } else if (progress < 50) {
            setLoadingText('Generating Invoice...');
        } else if (progress < 75) {
            setLoadingText('Warming up the printers...');
        } else {
            setLoadingText('Preparing your prints, almost there!');
        }
    };

    useEffect(() => {
        let interval;
        if (!isComplete) {
            interval = setInterval(() => {
                setProgress((oldProgress) => {
                    const newProgress = Math.min(oldProgress + 1, 99);
                    updateLoadingText(newProgress);
                    return newProgress;
                });
            }, 100);
        } else {
            setProgress(100);
            updateLoadingText(100);
            setTimeout(() => {
                if (onComplete) onComplete();
            }, 500);
        }

        return () => clearInterval(interval);
    }, [isComplete, onComplete]);

    return (
        <div className="popup-overlay">
            <div className="popup-content">
                <div className="popup-header">
                    <div className="gif-container">
                        <img src={gifSrc} alt="print-animation" className="gif-animation" />
                    </div>
                </div>
                <h2 className="loading-text">{loadingText}</h2>
                <ProgressBar progress={progress} />
            </div>
        </div>
    );
};

export default PopupProgressLoader;