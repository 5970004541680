import React, { createContext, useContext, useState, useEffect } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import moment from 'moment-timezone';
import supabase from './supabaseClient';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    const storedUserInfo = localStorage.getItem('userInfo');
    const storedIsLoggedIn = localStorage.getItem('isLoggedIn');
    if (storedUserInfo && storedIsLoggedIn === 'true') {
      setUserInfo(JSON.parse(storedUserInfo));
      setIsLoggedIn(true);
    }
  }, []);

  const getISTTimestamp = () => {
    return moment().tz('Asia/Kolkata').format('YYYY-MM-DDTHH:mm:ssZ');
};

const created_at = getISTTimestamp();
  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const userInfoResponse = await axios.get(
          'https://www.googleapis.com/oauth2/v3/userinfo',
          { headers: { Authorization: `Bearer ${tokenResponse.access_token}` } }
        );
        setUserInfo(userInfoResponse.data);
        setIsLoggedIn(true);
        localStorage.setItem('userInfo', JSON.stringify(userInfoResponse.data));
        localStorage.setItem('isLoggedIn', 'true');

        const googleSub = userInfoResponse.data.sub;

        const { data: existingUser, error: fetchError } = await supabase
          .from('users')
          .select('*')
          .eq('google_user_id', googleSub)
          .single();

        if (fetchError && fetchError.code !== 'PGRST116') {
          console.error('Error fetching user:', fetchError);
          return;
        }

        if (!existingUser) {
          const { data, error } = await supabase
            .from('users')
            .insert({
              name: userInfoResponse.data.name,
              email: userInfoResponse.data.email,
              google_user_id: googleSub,
              created_at: created_at
            });
          
          if (error) {
            console.error('Error inserting user:', error);
          } else {
            console.log('User inserted successfully:', data);
          }
        } else {
          console.log('User with this sub already exists. No action needed.');
        }
      } catch (error) {
        console.error('Error in login process:', error);
      }
    },
    onError: (errorResponse) => console.error('Login Failed:', errorResponse),
  });

  const logout = () => {
    setIsLoggedIn(false);
    setUserInfo(null);
    localStorage.removeItem('userInfo');
    localStorage.removeItem('isLoggedIn');
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, userInfo, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);