import React from 'react';
import './OrderConfirmedDialog.css';

const OrderConfirmedDialog = ({ onConfirm }) => {
  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
    }
  };

  return (
    <div className="order-confirmed-dialog">
      <div className="dialog-content">
        <div className="tick-animation">
          <svg width="100" height="100" viewBox="0 0 100 100">
            <circle cx="50" cy="50" r="45" />
            <path d="M20 50l15 15 30-30" />
          </svg>
        </div>
        <h2>Order Confirmed!</h2>
        <button onClick={handleConfirm} className="close-button">OK</button>
      </div>
    </div>
  );
};

export default OrderConfirmedDialog;