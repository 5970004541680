import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../../../AuthContext';
import './Navbar.css';
import logo from '../../../assets/CTRL_P.png';

function Navbar() {
  const { isLoggedIn, userInfo, login, logout } = useAuth();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleLoginClick = () => {
    if (!isLoggedIn) {
      login();
    } else {
      setDropdownOpen(!dropdownOpen);
    }
  };

  const handleLogout = (e) => {
    e.stopPropagation(); 
    logout();
    setDropdownOpen(false);
    window.location.reload();
  };

  const getFirstName = (fullName) => {
    return fullName.split(' ')[0];
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <a href='/'>
        <div className="navbar-logo">
          <img src={logo} alt="ctrlp_logo" className="ctrlp_logo" />
        </div>
        </a>
        <div className={`login-wrapper ${dropdownOpen ? 'dropdown-open' : ''}`} onClick={handleLoginClick} ref={dropdownRef}>
          {isLoggedIn && userInfo ? (
            <>
              <div className="user-info-container">
                <span className="user-info">Hi, {getFirstName(userInfo.name)}</span>
                <img src={userInfo.picture} alt="Profile" className="profile-picture" />
              </div>
              <div className={`dropdown ${dropdownOpen ? 'open' : ''}`}>
                <button className="logout-button" onClick={handleLogout}>
                  Sign Out
                </button>
              </div>
            </>
          ) : (
            <button className="login-button">
              Sign In
            </button>
          )}
        </div>
      </div>
    </nav>
  );
}

export default Navbar;