import React from 'react';
import './SignInPopup.css';
import signin_img from '../../assets/signin_popup_img.png';

const SignInPopup = ({ isOpen, onClose, onLogin }) => {
    if (!isOpen) return null;

    return (
        <div className="signin-popup-overlay">
            <div className="signin-popup">
                <div className="signin-content">
                    <img src={signin_img} alt="Sign In" className="signin-image" />
                    <p className="signin-message">Please sign in to continue</p>
                    <div className="button-group">
                        <button className="signin-button" onClick={onLogin}>Sign In</button>
                        <button className="cancel-signin-button" onClick={onClose}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignInPopup;