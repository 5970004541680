import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { uploadFile } from './supabaseClient';
import DragDropFile from './FormComponents/DragDropFile';
import FilePreview from './FormComponents/FilePreview';
import './Form.css';
import './FormComponents/FilePreview.css'
import { Document, Page, pdfjs } from 'react-pdf';
import { jsPDF } from 'jspdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const Form = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        files: [],
    });

    const [fileSettings, setFileSettings] = useState({});
    const [savedPreferences, setSavedPreferences] = useState([]);
    const [numPages, setNumPages] = useState({});
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(false);
    const [allPreferencesSaved, setAllPreferencesSaved] = useState(false);
    const [expandedAccordions, setExpandedAccordions] = useState([]);
    const [fileTypes, setFileTypes] = useState({});

    const defaultPreferences = {
        copies: '1',
        duplex: 'simplex',
        paper_size: 'A4',
        start_page: '1',
        end_page: '1',
        orientation: 'Portrait',
        color_mode: 'Monochrome'
    };

    const handlePrintStoreClick = () => {
        navigate('/print-store');
    };

    const onDocumentLoadSuccess = (fileId, pdf) => {
        setNumPages(prevNumPages => ({
            ...prevNumPages,
            [fileId]: pdf.numPages
        }));

        setFileSettings(prevSettings => ({
            ...prevSettings,
            [fileId]: {
                ...prevSettings[fileId],
                end_page: pdf.numPages.toString()
            }
        }));
    };

    const renderPagePreview = (file, preferences, fileId) => {
        const orientation = preferences.orientation || 'Portrait';
        const isMonochrome = preferences.color_mode === 'Monochrome';
        const isOriginalPdf = fileTypes[fileId];
        const startPage = parseInt(preferences.start_page) || 1;
        return (
            <div className={`preview-content ${orientation} ${isOriginalPdf ? 'original-pdf' : 'image-to-pdf'}`}>
                <Document
                    file={file}
                    onLoadSuccess={(pdf) => onDocumentLoadSuccess(fileId, pdf)}
                >
                    <Page
                        pageNumber={startPage}
                        onLoadError={(error) => console.error('Error loading PDF:', error)}
                        renderTextLayer={false}
                        renderAnnotationLayer={false}
                        className={isMonochrome ? 'monochrome' : ''}
                    />
                </Document>
            </div>
        );
    };

    const convertImageToPdf = async (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = async (event) => {
                const img = new Image();
                img.onload = () => {
                    const pdf = new jsPDF({
                        orientation: img.width > img.height ? 'l' : 'p',
                        unit: 'px',
                        format: [img.width, img.height]
                    });
                    pdf.addImage(img, 'JPEG', 0, 0, img.width, img.height);
                    const pdfBlob = pdf.output('blob');
                    const pdfFile = new File([pdfBlob], `${file.name.split('.')[0]}.pdf`, { type: 'application/pdf' });
                    resolve(pdfFile);
                };
                img.src = event.target.result;
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    };

    const handleChange = async (e) => {
        const { name, files } = e.target;
        if (name === 'files') {
            const processedFiles = await Promise.all(Array.from(files).map(async file => {
                let processedFile = file;
                let isOriginalPdf = file.type === 'application/pdf';

                if (!isOriginalPdf && file.type.startsWith('image/')) {
                    processedFile = await convertImageToPdf(file);
                }

                const fileId = Date.now() + Math.random();

                setFileTypes(prevTypes => ({
                    ...prevTypes,
                    [fileId]: isOriginalPdf
                }));

                return {
                    file: processedFile,
                    preview: URL.createObjectURL(processedFile),
                    id: fileId
                };
            }));

            setFormData(prevData => ({
                ...prevData,
                files: [...prevData.files, ...processedFiles]
            }));

            processedFiles.forEach(fileObj => {
                setFileSettings(prevSettings => ({
                    ...prevSettings,
                    [fileObj.id]: { ...defaultPreferences }
                }));
            });

            setExpandedAccordions(prevExpanded => {
                if (prevExpanded.length === 0) {
                    return [true, ...Array(processedFiles.length - 1).fill(false)];
                } else {
                    return [...prevExpanded, ...Array(processedFiles.length).fill(false)];
                }
            });
        }
    };

    const handleToggleAccordion = (index, forceState = null) => {
        setExpandedAccordions(prevExpanded =>
            prevExpanded.map((item, i) => {
                if (i === index) {
                    return forceState !== null ? forceState : !item;
                }
                return false;
            })
        );
    };

    const handleSavePreferences = (fileId, preferences) => {
        setSavedPreferences(prevPreferences => {
            const existingIndex = prevPreferences.findIndex(pref => pref.fileId === fileId);

            const updatedPreferences = existingIndex !== -1
                ? prevPreferences.map((pref, index) => index === existingIndex ? { fileId, preferences } : pref)
                : [...prevPreferences, { fileId, preferences }];

            localStorage.setItem('savedPreferences', JSON.stringify(updatedPreferences));

            const allSaved = formData.files.every(file =>
                updatedPreferences.some(pref => pref.fileId === file.id)
            );
            setAllPreferencesSaved(allSaved);

            return updatedPreferences;
        });
    };

    const [previewKeys, setPreviewKeys] = useState({});

    const handleFileSettingsChange = (fileId, settingName, value) => {
        setFileSettings(prevSettings => {
            const newSettings = {
                ...prevSettings,
                [fileId]: {
                    ...prevSettings[fileId],
                    [settingName]: value
                }
            };

            if (settingName === 'orientation' || settingName === 'color_mode') {
                setPreviewKeys(prevKeys => ({
                    ...prevKeys,
                    [fileId]: (prevKeys[fileId] || 0) + 1
                }));
            }

            setFormData(prevData => ({ ...prevData }));

            return newSettings;
        });
    };

    const handleRemoveFile = (index) => {
        setFormData(prevData => {
            const newFiles = [...prevData.files];
            const removedFile = newFiles[index];
            URL.revokeObjectURL(removedFile.preview);
            newFiles.splice(index, 1);

            setFileSettings(prevSettings => {
                const newSettings = { ...prevSettings };
                delete newSettings[removedFile.id];
                return newSettings;
            });

            return { ...prevData, files: newFiles };
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (formData.files.length === 0) {
            alert('Please upload at least one file.');
            setLoading(false);
            return;
        }

        const uploadedFiles = [];

        for (const fileObj of formData.files) {
            const savedPreference = savedPreferences.find(pref => pref.fileId === fileObj.id);
            const fileSettings = savedPreference ? savedPreference.preferences : defaultPreferences;

            if (parseInt(fileSettings.start_page, 10) > parseInt(fileSettings.end_page, 10)) {
                alert(`Start Page must be less than or equal to End Page for file ${fileObj.file.name}.`);
                setLoading(false);
                continue;
            }

            const { data: uploadData, error: uploadError, fileName } = await uploadFile(fileObj.file);

            if (uploadError) {
                console.error('Error uploading file:', uploadError);
                alert(`An error occurred while uploading ${fileObj.file.name}`);
                setLoading(false);
                continue;
            }

            const fileSize = calculateFileSize(fileSettings);

            uploadedFiles.push({
                fileName,
                fileSettings,
                fileId: fileObj.id,
                size: fileSize,
                source: 'print-pdf'
            });
        }

        const { totalPages } = calculateTotalPages();
        setTotalPages(totalPages);

        localStorage.setItem('uploadedFiles', JSON.stringify(uploadedFiles));

        navigate('/shop-selection', { state: { totalPages, source: 'print-pdf' } });

        setLoading(false);
    };

    const calculateFileSize = (settings) => {
        const startPage = parseInt(settings.start_page) || 1;
        const endPage = parseInt(settings.end_page) || 1;
        const copies = parseInt(settings.copies) || 1;
        return copies * (endPage - startPage + 1);
    };

    const calculateTotalPages = () => {
        let coloredPages = 0;
        let bwPages = 0;

        formData.files.forEach(fileObj => {
            const settings = fileSettings[fileObj.id] || defaultPreferences;
            const startPage = parseInt(settings.start_page) || 1;
            const endPage = parseInt(settings.end_page) || numPages[fileObj.id] || 1;
            const copies = parseInt(settings.copies) || 1;
            const pageCount = (endPage - startPage + 1) * copies;

            if (settings.color_mode === 'Color') {
                coloredPages += pageCount;
            } else {
                bwPages += pageCount;
            }
        });

        const totalPages = coloredPages + bwPages;

        return { coloredPages, bwPages, totalPages };
    };

    useEffect(() => {
        clearUploadedFiles();
        window.addEventListener('beforeunload', clearUploadedFiles);

        return () => {
            window.removeEventListener('beforeunload', clearUploadedFiles);
        };
    }, []);

    const clearUploadedFiles = () => {
        localStorage.removeItem('uploadedFiles');
        localStorage.removeItem('savedPreferences');
    };

    return (
        <div className="form-section">
            <div className="form-container">
                <div className="button-wrapper">
                    <button onClick={handlePrintStoreClick} className="print-store-button">
                        Print Store
                    </button>
                </div>
                <h1 className="form-heading">Upload Files with Print Settings</h1>
                <form className="form" name="jobs" id="jobs" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="files" className="form-label">Upload File:</label>
                        <DragDropFile
                            onFileSelect={handleChange}
                            name="files"
                            className="form-file-upload"
                            required
                            multiple={true}
                        />
                    </div>
                    {formData.files.length > 0 && (
                        <div className="form-group file-preview-container">
                            {formData.files.map((fileObj, index) => (
                                <div key={`${fileObj.id}-${previewKeys[fileObj.id] || 0}`} className="page-preview">
                                    {renderPagePreview(fileObj.file, fileSettings[fileObj.id], fileObj.id)}
                                </div>
                            ))}
                        </div>
                    )}
                    {formData.files.length > 0 && (
                        <div className="form-group file-preview-container">
                            {formData.files.map((fileObj, index) => (
                                <div key={fileObj.id} className="file-preview-item">
                                    <FilePreview
                                        file={fileObj.file}
                                        preview={fileObj.preview}
                                        onRemove={() => handleRemoveFile(index)}
                                        formData={fileSettings[fileObj.id]}
                                        handleChange={(e) => handleFileSettingsChange(fileObj.id, e.target.name, e.target.value)}
                                        onSave={(preferences) => {
                                            handleSavePreferences(fileObj.id, preferences);
                                            handleToggleAccordion(index, false);
                                        }}
                                        isExpanded={expandedAccordions[index]}
                                        onToggle={handleToggleAccordion}
                                        index={index}
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                    {formData.files.length > 0 && (
                        <div className="form-group total-pages-container">
                            <h3>Total Pages: {calculateTotalPages().totalPages}</h3>
                            <p>Colored Pages: {calculateTotalPages().coloredPages}</p>
                            <p>Black & White Pages: {calculateTotalPages().bwPages}</p>
                        </div>
                    )}
                    <div className="form-group">
                        {loading ? (
                            <div className='spinner-container'>
                                <div className="spinner"></div>
                            </div>
                        ) : (
                            <button
                                type="submit"
                                className="form-submit"
                                disabled={!allPreferencesSaved || formData.files.length === 0}
                            >
                                Proceed to Shop Selection
                            </button>
                        )}
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Form;