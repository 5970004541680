import React from 'react';
import upload from '../assets/upload.png'
import './DragDropFile.css'

function DragDropFile({ onFileSelect, name = 'files', className = '',multiple = true  }) {
  const [dragActive, setDragActive] = React.useState(false);
  const inputRef = React.useRef(null);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      onFileSelect({ target: { name, files: e.dataTransfer.files } });
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      onFileSelect(e);
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <form
    id="form-file-upload"
    className={className}
    onDragEnter={handleDrag}
    onSubmit={(e) => e.preventDefault()}
  >
    <input
      ref={inputRef}
      type="file"
      name={name}
      id="input-file-upload"
      multiple={multiple}
      onChange={handleChange}
    />
      <label
        id="label-file-upload"
        htmlFor="input-file-upload"
        className={dragActive ? 'drag-active' : ''}
      >
        <div>
          <img src={upload} alt='upload'></img>
          <br />
          <button type="button" className="upload-button" onClick={onButtonClick}>
            <b>Click to upload</b>
          </button>
          <br />
          <span className='drag-drop-text'>Drag and drop files here</span>
        </div>
      </label>
      {dragActive && (
        <div
          id="drag-file-element"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        ></div>
      )}
    </form>
  );
}

export default DragDropFile;
