import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import supabase from '../../supabaseClient';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShare } from '@fortawesome/free-solid-svg-icons';
import './ProductPage.css';

const AccordionItem = ({ title, content, isOpen, onClick }) => (
  <div className="accordion__item">
    <div className={`accordion__header ${isOpen ? 'active' : ''}`} onClick={onClick}>
      <span className="title-text">{title}</span>
    </div>
    <div className={`accordion__content ${isOpen ? 'open' : ''}`} style={{ maxHeight: isOpen ? '1000px' : '0' }}>
      <p>{content}</p>
    </div>
  </div>
);

const ProductPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [loading, setLoading] = useState(true);
  const [openAccordion, setOpenAccordion] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (id) {
          const { data, error } = await supabase
            .from('print_bazaar')
            .select('*, duplex, paper_size, start_page, end_page, orientation, color_mode')
            .eq('id', id)
            .single();

          if (error) throw error;
          setProduct(data);
        }
      } catch (error) {
        console.error('Error fetching data:', error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const handlePrintClick = () => {

    const fileSettings = {
      copies: quantity.toString(),
      duplex: product.duplex || 'simplex',
      paper_size: product.paper_size || 'A4',
      start_page: product.start_page || '1',
      end_page: product.end_page || product.pages?.toString() || '1',
      orientation: product.orientation || 'Portrait',
      color_mode: product.color_mode || 'Color'
    };

    const uploadedFiles = [{
      fileName: product.file_name,
      fileSettings,
      fileId: product.id,
      size: product.pages,
      source: 'print-store',
      pdf_link: product.pdf_link
    }];

    localStorage.setItem('uploadedFiles', JSON.stringify(uploadedFiles));
    localStorage.setItem('savedPreferences', JSON.stringify([{ fileId: product.id, preferences: fileSettings }]));

    navigate('/shop-selection', {
      state: {
        totalPages: product.pages,
        source: 'print-store',
        pdf_link: product.pdf_link,
        quantity
      }
    });
  };

  const handleQuantityChange = (action) => {
    if (action === "increase" && quantity < 10) {
      setQuantity(quantity + 1);
    } else if (action === "decrease" && quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const handleShareClick = () => {
    const currentUrl = window.location.href;

    const showMessage = (message) => {
      const messageElement = document.createElement('div');
      messageElement.textContent = message;
      messageElement.style.position = 'fixed';
      messageElement.style.bottom = '20px';
      messageElement.style.left = '50%';
      messageElement.style.transform = 'translateX(-50%)';
      messageElement.style.backgroundColor = 'rgba(0, 0, 0, 0.7)';
      messageElement.style.color = 'white';
      messageElement.style.padding = '10px 20px';
      messageElement.style.borderRadius = '5px';
      messageElement.style.zIndex = '1000';
      document.body.appendChild(messageElement);
      setTimeout(() => document.body.removeChild(messageElement), 2000);
    };

    const copyToClipboard = () => {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(currentUrl).then(() => {
          showMessage('URL copied');
        }).catch(() => {
          showMessage('Failed to copy URL');
        });
      } else {
        const textArea = document.createElement('textarea');
        textArea.value = currentUrl;
        document.body.appendChild(textArea);
        textArea.select();
        try {
          document.execCommand('copy');
          showMessage('URL copied');
        } catch (err) {
          showMessage('Failed to copy URL');
        }
        document.body.removeChild(textArea);
      }
    };

    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    if (isMobile && navigator.share) {
      navigator.share({
        url: currentUrl
      }).then(() => {
        showMessage('URL shared');
      }).catch((error) => {
        if (error.name !== 'AbortError') {
          copyToClipboard();
        }
      });
    } else {
      copyToClipboard();
    }

  };

  const toggleAccordion = (index) => {
    setOpenAccordion(openAccordion === index ? null : index);
  };

  if (loading) {
    return (
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        backgroundColor: '#111827',
      }} >
        <CircularProgress sx={{
          color: '#3b82f6',
        }}
          size={60}
        />
      </Box >
    );
  }

  if (!product) {
    return <p>Product not found</p>;
  }

  const faqItems = [
    {
      title: "Why should I use Ctrl+P Print Bazaar?",
      content: "Ctrl+P Print Bazaar offers a streamlined way to access study materials without downloading files. It provides a convenient platform for students to find and print the resources they need quickly and efficiently."
    },
    {
      title: "How do I print a document?",
      content: "To print a document, simply browse the available notes, check the description, and select a nearby print store. Choose your preferred printing options, make the payment, and your document will be ready for pickup at the selected store."
    },
    {
      title: "What happens to the payment I make for chargeable documents?",
      content: "When you pay for a chargeable document, a portion of the amount goes to the original author or creator as a royalty. This helps support the creation of quality content while keeping costs reasonable for students."
    },
    {
      title: "Can I download the document as a PDF?",
      content: "No, the documents are meant exclusively for printing. This helps protect the intellectual property of the content creators and ensures fair usage of the materials."
    },
    {
      title: "What payment methods are accepted?",
      content: "We accept a variety of payment methods to ensure convenience for all users. You can pay through Credit Card, Debit Card, Net Banking, UPI, and popular digital Wallets."
    }
  ];

  return (
    <div className="product-section">
      <div className="product-page">
        <div className="product-content">
          <div className="product-container">
            <div className="product-image-container">
              <div className="image-actions">
                <button className="action-button" onClick={handleShareClick}>
                  <FontAwesomeIcon icon={faShare} /> Share
                </button>
              </div>
              <img src={product.cover_image_url} alt={product.file_name} className="product-image" />
              <div className="seller-info">
                <h2>Sold By</h2>
                <div className="seller-details">
                  <span className="seller-name">{product.author_name}</span>
                </div>
              </div>
            </div>

            <div className="product-details">
              <div className="price-container">
                <h1>{product.file_name}</h1>
                <span className="current-price">FREE</span>
                <span className="price-info">Powered by CTRL P</span>
              </div>
              <p className="shipping-info">Printing will be scheduled to your nearest shop By CTRLP</p>

              <div className="quantity-section">
                <label htmlFor="quantity" className="quantity-label">Quantity:</label>
                <div className="qty-input">
                  <button className="qty-count qty-count--minus" data-action="minus" onClick={() => handleQuantityChange("decrease")} type="button">-</button>
                  <input className="product-qty" name="product-qty" value={quantity} readOnly min="1" max="10" />
                  <button className="qty-count qty-count--add" data-action="add" onClick={() => handleQuantityChange("increase")} type="button">+</button>
                </div>
              </div>

              <button onClick={handlePrintClick} className="print-button">
                Print Now
              </button>

              <hr className="separator-line" />
              <div className="product-meta">
                <div className="meta-item">
                  <span className="meta-label">Type</span>
                  <span className="meta-value">Note</span>
                </div>
                <div className="meta-item">
                  <span className="meta-label">Category</span>
                  <span className="meta-value">{product.category || 'N/A'}</span>
                </div>
                <div className="meta-item">
                  <span className="meta-label">Author</span>
                  <span className="meta-value">{product.author_name}</span>
                </div>
                <div className="meta-item">
                  <span className="meta-label">Pages</span>
                  <span className="meta-value">{product.pages}</span>
                </div>
              </div>
            </div>
          </div>
          <hr className="separator-line" />
          <div className="product-description">
            <h2>Description</h2>
            <div dangerouslySetInnerHTML={{ __html: product.description }} />
          </div>
          <hr className="separator-line" />
        </div>

        <div className="how-it-works-section">
          <h2>How Does it Work?</h2>
          <div className="slider-container">
            <div className="slider-item">
              <img src="https://gxbdltjmtvrtsomvbrgv.supabase.co/storage/v1/object/public/print-store/1.png" alt="Step 1" />
            </div>
            <div className="slider-item">
              <img src="https://gxbdltjmtvrtsomvbrgv.supabase.co/storage/v1/object/public/print-store/3.png" alt="Step 3" />
            </div>
            <div className="slider-item">
              <img src="https://gxbdltjmtvrtsomvbrgv.supabase.co/storage/v1/object/public/print-store/2.png" alt="Step 2" />
            </div>
          </div>
        </div>

        <div className="faq-section">
          <h2>Frequently Asked Questions</h2>
          <div className="accordion">
            {faqItems.map((item, index) => (
              <AccordionItem
                key={index}
                title={item.title}
                content={item.content}
                isOpen={openAccordion === index}
                onClick={() => toggleAccordion(index)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductPage;
