import { createClient } from '@supabase/supabase-js';
import moment from 'moment-timezone';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY

const supabase = createClient(supabaseUrl, supabaseKey);

const getISTTimestamp = () => {
  return moment().tz('Asia/Kolkata').format('YYYY-MM-DDTHH:mm:ssZ');
};

export const insertData = async (data,source) => {
  const created_at = getISTTimestamp();
  const { error } = await supabase
    .from('jobs')
    .insert([{...data, created_at,source}], { returning: 'minimal' });
  return { error };
};

export const uploadFile = async (file) => {
  const fileName = `${Date.now()}_${file.name}`;
  const { data, error } = await supabase
      .storage
      .from('print-pdf')
      .upload(fileName, file);
  
  return { data, error, fileName };
};
export default supabase;
