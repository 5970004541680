import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import supabase from '../../supabaseClient';
import './PrintStore.css';

const categories = [
  { name: 'Best Seller', icon: 'https://gxbdltjmtvrtsomvbrgv.supabase.co/storage/v1/object/public/print-store/icons/icon1.png' },
  { name: "Today's Deal", icon: 'https://gxbdltjmtvrtsomvbrgv.supabase.co/storage/v1/object/public/print-store/icons/TodaysDeal.png' },
  { name: 'Award Winners', icon: 'https://gxbdltjmtvrtsomvbrgv.supabase.co/storage/v1/object/public/print-store/icons/icon8.png' },
  { name: 'International Best Sellers', icon: 'https://gxbdltjmtvrtsomvbrgv.supabase.co/storage/v1/object/public/print-store/icons/icon7.png' },
  { name: 'New Arrivals', icon: 'https://gxbdltjmtvrtsomvbrgv.supabase.co/storage/v1/object/public/print-store/icons/icon2.png' },
];

const scrollingTexts = [
  "Get flat 10% off on all purchases! | Free shipping on orders above ₹500 | Use Code: BW10OFF",
  "Check out the latest arrivals and best deals today!",
  "Hurry! Limited-time offers on bestsellers and award-winning books.",
];

const PrintStore = () => {
  const navigate = useNavigate();
  const [trendingBooks, setTrendingBooks] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [currentScrollingText, setCurrentScrollingText] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentScrollingText((prev) => (prev + 1) % scrollingTexts.length);
    }, 3000); // 3 seconds interval
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    fetchTrendingBooks();
  }, [selectedCategory]);

  const fetchTrendingBooks = async () => {
    try {
      let query = supabase
        .from('print_bazaar')
        .select('*')
        .eq('approved', 'true')
        .limit(10);

      if (selectedCategory === 'Best Seller') {
        query = query.eq('subcat', 'bestselling');
      } else if (selectedCategory) {
        query = query.or(`subcat.eq.${selectedCategory},subcat2.eq.${selectedCategory},subcat3.eq.${selectedCategory},subcat4.eq.${selectedCategory},subcat5.eq.${selectedCategory}`);
      }

      const { data, error } = await query;

      if (error) throw error;
      setTrendingBooks(data);
    } catch (error) {
      console.error('Error fetching trending books:', error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data, error } = await supabase
        .from('print_bazaar')
        .select('*')
        .or(`file_name.ilike.%${searchQuery}%,category.ilike.%${searchQuery}%,subcat.ilike.%${searchQuery}%,subcat2.ilike.%${searchQuery}%,subcat3.ilike.%${searchQuery}%,subcat4.ilike.%${searchQuery}%,subcat5.ilike.%${searchQuery}%`)
        .eq('approved', 'true')
        .limit(10);

      if (error) throw error;
      setTrendingBooks(data);
    } catch (error) {
      console.error('Error searching books:', error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleViewProduct = (bookId) => {
    navigate(`/product/${bookId}`);
  };

  if (loading) {
    return (
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        backgroundColor: '#111827',
      }} >
        <CircularProgress sx={{
          color: '#3b82f6',
        }}
          size={60}
        />
      </Box >
    );
  }

  return (
    <div className="printstore-container">
      {/* Scrolling Nav Bar */}
      <div className="scrolling-nav">
        <p>{scrollingTexts[currentScrollingText]}</p>
      </div>

      {/* Search Bar */}
      <div className="search-bar">
        <form onSubmit={handleSearch}>
          <input
            type="text"
            placeholder="Search By Title, Author, Publisher or ISBN"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <button type="submit">Search</button>
        </form>
      </div>

      {/* Banner */}
      <div className="banner">
        <img
          src="https://gxbdltjmtvrtsomvbrgv.supabase.co/storage/v1/object/public/print-store/home.jpg"
          alt="Print Store Banner"
        />
      </div>

      {/* Horizontal Line above Category */}
      <div className="horizontal-line"></div>

      {/* Categories */}
      <div className="categories">
        {categories.map((category, index) => (
          <div
            key={index}
            className={`category-item ${selectedCategory === category.name ? 'active' : ''}`}
            onClick={() => setSelectedCategory(category.name)}
          >
            <img src={category.icon} alt={category.name} />
            <p>{category.name}</p>
          </div>
        ))}
      </div>

      <div className="horizontal-line"></div>

      <h2 className="books-heading">{selectedCategory || 'Best Selling'}</h2>
      <div className="trending-books">
        {trendingBooks.map((book) => (
          <div key={book.id} className="book-card">
            <img src={book.cover_image_url} alt={book.file_name} className="book-cover" />
            <div className="book-info">
              <h3>{book.file_name}</h3>
              <p>{book.author_name}</p>
              <Link to={`/product/${book.id}`} className="view-button">View</Link>
            </div>
          </div>
        ))}
      </div>

      <h2 className="books-heading">Trending Section</h2>
      <div className="trending-books">
        {trendingBooks.map((book) => (
          <div key={book.id} className="book-card">
            <img src={book.cover_image_url} alt={book.file_name} className="book-cover" />
            <div className="book-info">
              <h3>{book.file_name}</h3>
              <p>{book.author_name}</p>
              <button
                className="view-button"
                onClick={() => handleViewProduct(book.id)}
              >
                View
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>


  );
};

export default PrintStore;