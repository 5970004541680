import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useAuth } from '../../AuthContext';
import supabase from '../../supabaseClient';
import './ShopSelection.css';
import SignInPopup from './SignInPopup';

const ShopSelection = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [institutes, setInstitutes] = useState([]);
    const [filteredInstitutes, setFilteredInstitutes] = useState([]);
    const [selectedInstitute, setSelectedInstitute] = useState('');
    const [shops, setShops] = useState([]);
    const [filteredShops, setFilteredShops] = useState([]);
    const [selectedShop, setSelectedShop] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const { isLoggedIn, login } = useAuth();

    const totalPages = location.state?.totalPages;
    const source = location.state?.source;

    useEffect(() => {
        if (!totalPages) {
            navigate('/');
        } else {
            initializeData();
        }
    }, [totalPages, source, navigate]);

    useEffect(() => {
        if (isLoggedIn && isPopupOpen) {
            setIsPopupOpen(false);
            navigateToOrderSummary();
        }
    }, [isLoggedIn, isPopupOpen]);

    const initializeData = async () => {
        setLoading(true);
        try {
            const savedPreferences = JSON.parse(localStorage.getItem('savedPreferences')) || [];
            if (savedPreferences.length > 0) {
                const { requiresColor, requiresDuplex } = analyzePreferences(savedPreferences);
                const shopFilters = generateFiltersForFiles(requiresColor, requiresDuplex);
                await filterShopsBasedOnPreferences(shopFilters);
            } else {
                await fetchInstitutes();
                await fetchAllShops();
            }
        } catch (err) {
            console.error('Error initializing data:', err);
            setError('Failed to load initial data');
        } finally {
            setLoading(false);
        }
    };

    const analyzePreferences = (preferences) => {
        let requiresColor = false;
        let requiresDuplex = false;

        preferences.forEach(file => {
            if (file.preferences.color_mode === 'Color') {
                requiresColor = true;
            }
            if (file.preferences.duplex !== 'simplex') {
                requiresDuplex = true;
            }
        });

        return { requiresColor, requiresDuplex };
    };

    const generateFiltersForFiles = (requiresColor, requiresDuplex) => {
        let filters = [];

        if (requiresColor && requiresDuplex) {
            filters.push({ color_capable: true, duplex_capable: true });
        } else if (requiresColor) {
            filters.push({ color_capable: true });
        } else if (requiresDuplex) {
            filters.push({ duplex_capable: true });
        } else {
            filters.push({});
        }

        return filters;
    };

    const filterShopsBasedOnPreferences = async (filters) => {
        try {
            let allShops = [];
            for (let filter of filters) {
                const { data, error } = await supabase
                    .from('institute_print_shops')
                    .select('shop_id, shop_name, institute_name, current_workload, color_capable, duplex_capable')
                    .match(filter)
                    .eq('is_online', true);

                if (error) throw error;

                allShops = [...allShops, ...data];
            }

            const uniqueShops = Array.from(new Set(allShops.map(shop => shop.shop_name)))
                .map(name => allShops.find(shop => shop.shop_name === name));

            setFilteredShops(uniqueShops.map(item => ({
                id: item.shop_id,
                name: item.shop_name,
                institute: item.institute_name,
                workload: item.current_workload,
                trafficStatus: getTrafficStatus(item.current_workload),
            })));

            const uniqueInstitutes = [...new Set(uniqueShops.map(shop => shop.institute_name))];
            setInstitutes(uniqueInstitutes);
        } catch (error) {
            console.error('Error fetching filtered shops:', error);
            setError('Failed to load filtered shops');
        }
    };

    const fetchInstitutes = async () => {
        try {
            const { data, error } = await supabase
                .from('institute_print_shops')
                .select('institute_name');

            if (error) throw error;

            const uniqueInstitutes = [...new Set(data.map(item => item.institute_name))];
            setInstitutes(uniqueInstitutes);
        } catch (error) {
            console.error('Error fetching institutes:', error);
            setError('Failed to load institutes');
        }
    };

    const fetchAllShops = async () => {
        try {
            const { data, error } = await supabase
                .from('institute_print_shops')
                .select('shop_id, shop_name, institute_name, current_workload, color_capable, duplex_capable')
                .eq('is_online', true);

            if (error) throw error;

            const formattedShops = data.map(item => ({
                id: item.shop_id,
                name: item.shop_name,
                institute: item.institute_name,
                workload: item.current_workload,
                trafficStatus: getTrafficStatus(item.current_workload),
            }));

            setFilteredShops(formattedShops);
        } catch (error) {
            console.error('Error fetching all shops:', error);
            setError('Failed to load shops');
        }
    };

    const getTrafficStatus = (workload) => {
        if (workload < 50) {
            return { text: 'Low Traffic', color: 'green', textColor: 'white' };
        } else if (workload >= 50 && workload <= 100) {
            return { text: 'Medium Traffic', color: 'yellow', textColor: 'black' };
        } else {
            return { text: 'High Traffic', color: 'red', textColor: 'white' };
        }
    };

    const handleInstituteChange = (e) => {
        const value = e.target.value;
        setSelectedInstitute(value);
        if (value && value.trim() !== '') {
            setFilteredInstitutes(
                institutes.filter(institute =>
                    institute && institute.toLowerCase().includes(value.toLowerCase())
                )
            );
            const shopsForInstitute = filteredShops.filter(shop =>
                shop.institute.toLowerCase().includes(value.toLowerCase())
            );
            setShops(shopsForInstitute);
        } else {
            setFilteredInstitutes([]);
            setShops([]);
        }
    };

    const handleInstituteSelect = (institute) => {
        setSelectedInstitute(institute);
        setFilteredInstitutes([]);
        const shopsForInstitute = filteredShops.filter(shop => shop.institute === institute);
        setShops(shopsForInstitute);
    };

    const handleShopSelect = (shop) => {
        setSelectedShop(shop);
    };

    const handleViewOrderSummary = () => {
        if (selectedShop) {
            if (isLoggedIn) {
                navigateToOrderSummary();
            } else {
                setIsPopupOpen(true);
            }
        } else {
            setError('Please select a shop before proceeding.');
        }
    };

    const navigateToOrderSummary = () => {
        const uploadedFiles = JSON.parse(localStorage.getItem('uploadedFiles')) || [];
        const formattedFiles = uploadedFiles.map(file => ({
            ...file,
            fileSettings: {
                copies: file.fileSettings.copies || "1",
                start_page: file.fileSettings.start_page || "1",
                end_page: file.fileSettings.end_page || "1",
                color_mode: file.fileSettings.color_mode || "Black & White",
                duplex: file.fileSettings.duplex || "simplex",
                paper_size: file.fileSettings.paper_size || "A4",
                orientation: file.fileSettings.orientation || "Portrait",
            },
            source: file.source || source
        }));
        const orderData = {
            shop: selectedShop,
            files: formattedFiles,
        };

        setIsPopupOpen(false);

        navigate('/order_confirmation', { state: { orderData } });
    };

    const handleLogin = async () => {
        try {
            await login();
        } catch (error) {
            console.error('Login failed:', error);
            setError('Login failed. Please try again.');
        }
    };

    if (loading) {
        return (
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'fixed',
                width: '100vw',
                height: '100vh',
                backgroundColor: '#111827',
            }} >
                <CircularProgress sx={{
                    color: '#3b82f6',
                }}
                    size={60}
                />
            </Box >
        );
    }
    if (error) return <div>Error: {error}</div>;

    return (
        <div className="shop-selection-container">
            <div className="shop-selection-content">
                <div className="shop-selection-header">
                    <h1>Shop Selection</h1>
                </div>

                <div className="institute-selection">
                    <label htmlFor="institute">Select Institute</label>
                    <input
                        type="text"
                        id="institute"
                        value={selectedInstitute}
                        onChange={handleInstituteChange}
                        placeholder="Type to search for an institute"
                    />
                    {filteredInstitutes.length > 0 && (
                        <ul className="institute-list">
                            {filteredInstitutes.map((institute, index) => (
                                <li key={index} onClick={() => handleInstituteSelect(institute)}>
                                    {institute}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>

                {selectedInstitute && (
                    <div className="shop-list">
                        <h2>Shops in {selectedInstitute}</h2>
                        {shops.length > 0 ? (
                            <ul>
                                {shops.map((shop, index) => (
                                    <li
                                        key={index}
                                        onClick={() => handleShopSelect(shop)}
                                        className={`shop-item ${selectedShop === shop ? 'selected' : ''}`}
                                    >
                                        <div className="shop-info">
                                            <div className="shop-icon">
                                                <i className="fas fa-store"></i>
                                            </div>
                                            <span className="shop-name">{shop.name}</span>
                                        </div>
                                        <div
                                            className="traffic-status"
                                            style={{
                                                backgroundColor: shop.trafficStatus.color,
                                                color: shop.trafficStatus.textColor
                                            }}
                                        >
                                            {shop.trafficStatus.text}
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p>No shops found for this institute.</p>
                        )}
                    </div>
                )}

                <button
                    className="proceed-button"
                    onClick={handleViewOrderSummary}
                    disabled={!selectedShop}
                >
                    View Order Summary
                </button>

                {error && <p className="error-message">{error}</p>}
            </div>

            <SignInPopup
                isOpen={isPopupOpen}
                onClose={() => setIsPopupOpen(false)}
                onLogin={handleLogin}
            />
        </div>
    );
};

export default ShopSelection;
